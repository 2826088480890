.container {
  display: flex;
  justify-content: center;
}
.container a {
  display: inline-block;
  padding: 0.7rem 2rem;
  background-color: var(--p);
  color: #fff;
  transition: all 0.2s linear;
  font-size: 1rem;
  border-radius: 5px;
  font-weight: 600;
  letter-spacing: 2px;
}
.container a:hover {
  background-color: transparent;
  color: var(--p);
  box-shadow: rgba(0, 114, 203, 0.16) 0px 1px 2px,
    rgb(0, 114, 203) 0px 0px 0px 1.5px;
}
