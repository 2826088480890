.container {
  padding: var(--page_padding);
  padding-top: 0rem;
  position: relative;
}
.brand {
  width: 100%;
  padding: var(--page_padding);
  box-shadow: rgba(17, 12, 46, 0.15) 0px 12px 50px 0px;
  border-radius: 1rem;
  display: flex;
}
.image {
  width: 132px;
  height: 132px;
  margin-right: var(--page_padding);
  flex-shrink: 0;
}
.image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.text {
  padding: 0rem var(--page_padding);
}
.text p {
  font-size: 14px;
}
.buttons {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.buttons button {
  padding: 0.5rem 1.5rem;
  background-color: var(--p);
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0.3rem;
  transition: all 0.2s linear;
}
.buttons button:first-child {
  margin-right: 1rem;
}
.buttons button:hover {
  background-color: var(--d);
}
.buttons button img {
  width: 20px;
  height: 20px;
  margin-bottom: -5px;
  filter: invert();
  transition: all 0.2s linear;
}
.buttons button:active img {
  transform: scale(1.3);
}

@media screen and (max-width: 600px) {
  .brand {
    flex-direction: column;
    align-items: center;
  }
  .image {
    margin-right: 0rem;
    margin-bottom: var(--page_padding);
  }
}
