.container {
  width: 100%;
  padding: var(--page_padding);
  padding-top: 0rem;
}
.quote {
  display: flex;
  width: 70%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 12px 25px 0px;
  margin-bottom: var(--page_padding);
}
.quote:last-child {
  margin-bottom: 0rem;
}
.quote:nth-child(even) {
  margin-left: auto;
}
.image {
  width: 150px;
  margin-right: var(--page_padding);
  flex-shrink: 0;
}
.image img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.text {
  margin-bottom: 1rem;
}
.text img {
  width: 15px;
  height: 15px;
}
.text img:first-child {
  margin-bottom: 10px;
  margin-right: 5px;
}
.text img:last-child {
  margin-bottom: -12px;
  margin-left: 5px;
}
.info p:first-child {
  font-size: 14px;
}
.info p:last-child {
  font-size: 12px;
}

@media screen and (max-width: 992px) {
  .quote {
    width: 100%;
    flex-direction: column;
  }
  .image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--page_padding);
    margin-right: 0rem;
  }
}
