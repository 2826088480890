.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





/* ORGINAL CSS */
.site-container {
  min-height: 100vh;
}

main {
  flex: 1;
  background-color: #e8ecec;
}

a {
  text-decoration: none;
}

.rating {
  color: rgb(173, 173, 12);
}
.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product {
  border: 1px solid black;
  margin: 1rem;
  border-radius: 5%;
}

.product img {
  width: 100%;
  max-width: 240px;
  border-radius: 5%;
}

.card-img-top {
  max-height: 173px;
}

.product a {
  text-decoration: none;
  font-weight: 700;
}

.product-info {
  padding: 1rem;
}

.add-to-cart-button {
  float: right;
  margin-top: -2.5rem;
}

.img-large {
  max-width: 100%;
}
.img-thumbnail {
  height: 80px;
}

.small-container {
  max-width: 600px;
}

footer {
  background: black;
  color: wheat;
  padding-top: 1rem;
}

.fp {
  text-decoration: underline;
  margin-bottom: 2rem;
}

.checkout-steps > div {
  border-bottom: 2rem solid #a0a0a0;
  color: #a0a0a0;
}

.checkout-steps > div.active {
  border-bottom: 2rem solid #f08000;
  color: #f08000;
}

.float-right {
  float: right;
}

.search {
  border: 1px solid darkgray;
  margin-right: 5px;
  height: 2rem;
  border-radius: 5px;
  padding: 5px;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.card {
  background-color: #a8dcf4;
}

.card-header {
  font-weight: 900;
  background-color: rgb(66, 180, 231);
}

.no-gutter {
  margin-right: 0;
  margin-left: 0;
  padding-left: 0;
  margin: auto;
  padding-right: 0;
}

.inbody-button {
  background: rgb(66, 180, 231);
  width: 80%;
  margin: auto;
  padding: 0.5rem;
  color: white;
  border-radius: 5px;
}

.full_screen {
  width: 100vw;
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}

.full-height {
  height: 100vh;
  background-color: #a8dcf4;
}

.img-thumbnail {
  margin-bottom: 35px;
  width: 120px;
  height: 120px;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

