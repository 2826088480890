.accordion-button {
  padding: 5px;
  background-color: #219fd3;
  color: white;
  margin-bottom: 15px;
  border-radius: 10px;
}

.accordion-item {
  background-color: #42b4e7;
  border: none;
}

.accordion-body {
  background: #e7f1ff;
  margin-top: -20px;
  border-radius: 0 0 15px 15px;
}
