.container {
  padding: var(--page_padding);
  display: flex;
}
.text {
  margin-right: var(--page_padding);
}
.text h1 {
  color: var(--p);
  line-height: 1;
  margin-bottom: 1rem;
}
.text h3 {
  margin-bottom: 0.3rem;
}
.text p {
  font-size: 14px;
}
.image {
  width: 400px;
  height: 300px;
  flex-shrink: 0;
}
.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 12px 25px 0px;
}
@media screen and (max-width: 950px) {
  .container {
    flex-direction: column;
  }
  .text {
    order: 2;
  }
  .text h1 {
    text-align: center;
  }
  .image {
    width: 100%;
    height: auto;
    order: 1;
    margin-bottom: 1.5rem;
  }
}
