.container {
  width: 100%;
  height: 100vh;
  display: flex;
}
.text,
.image {
  width: 50%;
  height: 100%;
}
.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--page_padding);
}
.text img {
  width: 130px;
  margin-bottom: 1rem;
}
.text h1 {
  color: var(--p);
}
.text h3 {
  margin: 0.5rem 0rem 1rem 0rem;
}
.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 992px) {
  .container {
    flex-direction: column;
    height: auto;
  }
  .image {
    order: 1;
  }
  .text {
    order: 2;
  }
  .text,
  .image {
    width: 100%;
    height: auto;
  }
  .image img {
    height: auto;
    object-fit: initial;
  }
}
