

.box {
  width: 80;
  height: fit-content;
  border-radius: 20px;
  padding: 10px;
  text-align: center;
  background: #ededed;
  margin-top: 10px;
  margin-bottom: 10px;
}

.image img {
  min-height: 120px;
  max-height: 120px;
  min-width: 120px;
  max-width: 120px;
  border-radius: 50%;
  display: block;
  margin: 0 auto 5px;
}

.name {
  font-size: 1.25em;
  font-weight: bolder;
  letter-spacing: 1px;
}

.job_title {
  font-size: 0.65em;
  font-weight: bolder;
  color: gray;
  margin-top: -2px;
}

.button {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
}

.button button {
  width: 60;
  height: 50;
  padding: 3px;
  border-radius: 10px;
  margin: 5px;
}

.button .message {
  background: #ededed;
  border: 2px solid #000;
}

.button .connect {
  background-color: #000;
  color: #ededed;
  border: none;
}

button.connect:hover, button.message:hover {
  letter-spacing: 1px;
  transition: 0.5s;
}
