.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #dc3545;
  color: white;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 20px;
  font-size: 24px;
  animation: floating 2s ease-in-out infinite;
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
