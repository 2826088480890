@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  list-style-type: none;
  text-decoration: none;
  box-sizing: border-box;
}
:root {
  /* Primary */
  --p: #0072cb;
  /* Secondary */
  --s: #198754;
  /* Primary Bright */
  --p_b: #42b4e7;
  /* Primary Light */
  --p_l: #ecf8fd;
  /* Primary Light Dark */
  --p_l_d: #9ddaf3;
  /* White Dark */
  --w_d: #f7f7f7;
  /* Dark */
  --d: #151414;
  /* Dark Light */
  --d_l: #202020;
  --disable: #a9a9a9;
  --page_padding: 3rem;
}

@media screen and (max-width: 768px) {
  :root {
    --page_padding: 1.5rem;
  }
}




/* ORGINAL CSS */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ------- CUSTOM CSS ----- */

#toshow {
  display: none;
}
#tohover:hover > #toshow {
  display: block;
}
