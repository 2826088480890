.container {
  width: 100%;
  padding: var(--page_padding);
}
.inner {
  width: 100%;
  background-color: var(--p_l);
  border-radius: 1rem;
  padding: 2rem 0rem;
  text-align: center;
  display: flex;
  align-items: center;
}
.inner h1 {
  display: inline-block;
  color: var(--p);
  border: 1px solid var(--p);
  border-radius: 100px;
  padding: 0.5rem 2rem;
  flex-shrink: 0;
  text-transform: uppercase;
}
.inner span {
  display: inline-block;
  width: 100%;
  height: 1px;
  background-color: var(--p);
}

@media screen and (max-width: 768px) {
  .inner {
    padding: 1rem 0rem;
  }
  .inner h1 {
    font-size: 20px;
  }
}
