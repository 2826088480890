.container {
  width: 100%;
  height: 100%;
  padding: var(--page_padding);
  background-color: var(--w_d);
}
.inner {
  display: flex;
  width: 100%;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  overflow: hidden;
}
.feature {
  width: 25%;
  height: 100%;
  padding: 1.5rem;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.feature:last-child {
  border-right: none;
}
.f_container {
  width: 100%;
  display: flex;
  margin-bottom: 0.5rem;
}
.icon_container {
  display: flex;
  align-items: center;
}
.icon {
  width: 60px;
  height: 60px;
  background-color: var(--p_l);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s linear;
  margin-right: 1rem;
  flex-shrink: 0;
}
.icon img {
  width: 30px;
  height: 30px;
  transition: all 0.2s linear;
}
.text {
  width: calc(100% - 60px - 1rem);
}
.title {
  margin-bottom: 0.5rem;
}
.title h2 {
  font-size: 1rem;
  color: var(--p);
}
.description p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8rem;
}
.read_more {
  width: 100%;
}
.read_more a {
  width: 100%;
  display: inline-block;
  color: #fff;
  text-align: center;
  font-size: 0.8rem;
  padding: 0.5rem;
  background-color: var(--p);
  border-radius: 4px;
  transition: all 0.2s linear;
}
.read_more a:hover {
  background-color: transparent;
  color: var(--p);
  box-shadow: rgba(0, 114, 203, 0.16) 0px 1px 2px,
    rgb(0, 114, 203) 0px 0px 0px 1.5px;
}
@media screen and (max-width: 1124px) {
  .inner {
    flex-direction: column;
  }
  .feature {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .feature:last-child {
    border-bottom: none;
  }
}
