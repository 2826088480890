.container {
  width: 100%;
  padding: var(--page_padding);
  display: flex;
  justify-content: space-between;
}
.banner {
  width: 48%;
}
.title {
  margin-bottom: 1.5rem;
  color: var(--p);
}
.right_text {
  text-align: right;
}
.slider {
  border-radius: 0.5rem;
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 24px 50px 0px;
}
.slider img {
  width: 100%;
  position: absolute;
  top: 0;
  transition: transform 0.2s linear;
}
.buttons {
  margin-top: 2rem;
}
.buttons button {
  padding: 0.5rem 1.5rem;
  background-color: var(--p);
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 0.3rem;
  transition: all 0.2s linear;
}
.right_buttons {
  display: flex;
  justify-content: flex-end;
}
.buttons button:first-child {
  margin-right: 1rem;
}
.buttons button:hover {
  background-color: var(--d);
}
.buttons button img {
  width: 20px;
  height: 20px;
  margin-bottom: -5px;
  filter: invert();
  transition: all 0.2s linear;
}
.buttons button:active img {
  transform: scale(1.3);
}

@media screen and (max-width: 950px) {
  .container {
    flex-direction: column;
  }
  .banner {
    width: 100%;
  }
  .title {
    padding: 0.5rem 2rem;
    border: 1px solid var(--p);
    border-radius: 50px;
  }
  .title h2 {
    text-align: center;
  }
  .banner:last-child {
    margin-top: 1.5rem;
  }
  .buttons {
    display: flex;
    justify-content: center;
  }
}
