.main_slider_container {
  width: 100%;
  height: calc(100vh - 85px);
  position: relative;
}
.slider {
  height: calc(100vh - 85px) !important;
}
.slider a {
  z-index: 3 !important;
  transition: all 0.2s linear !important;
}
.animateIn,
.animateOut {
  transition: all 0.8s ease !important;
}
.slider a svg {
  filter: invert();
}
.slider_image_container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
  z-index: 1;
}
.centered_slider_items {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.centered_slider_items h1 {
  color: var(--p);
  opacity: 0;
  transform: translateY(-20px);
  animation: titleReverse 0.2s ease 0s 1 forwards;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 0.5rem;
}
.current .centered_slider_items h1 {
  animation: title 0.2s ease 0s 1 forwards;
}
.centered_slider_items p {
  max-width: 660px;
  color: #fff;
  text-align: center;
  padding: 0rem var(--page_padding);
  opacity: 0;
  transform: translateY(20px);
  animation: subtitleReverse 0.2s ease 0s 1 forwards;
}
.current .centered_slider_items p {
  animation: subtitle 0.2s ease 0s 1 forwards;
}
.slider_scroll_helper {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  height: 100%;
}
@keyframes title {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes titleReverse {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}
@keyframes subtitle {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes subtitleReverse {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}
@media screen and (max-width: 450px) {
  .main_slider_container {
    height: calc(100vh - 74.5px) !important;
  }
  .slider {
    height: calc(100vh - 74.5px) !important;
  }
}
@media screen and (max-width: 750px) {
  .centered_slider_items h1 {
    width: 100%;
    padding: 0rem var(--page_padding);
    text-align: center;
    margin-bottom: 0.5rem;
    color: var(--p_l_d);
    background-color: transparent;
  }
  .slider a {
    display: none !important;
  }
}
@media screen and (max-width: 1100px) {
  .slider_scroll_helper {
    z-index: 2;
  }
}
