.container {
  width: 100%;
  display: flex;
  padding: 1.5rem var(--page_padding);
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
  position: relative;
  z-index: 100;
}
.logo {
  width: 137px;
  margin-right: 1rem;
  flex-shrink: 0;
}
.logo a {
  display: inline-block;
  height: 30px;
}
.logo a img {
  height: 100%;
}
.nav {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icons {
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.nav ul,
.icons ul {
  display: flex;
}
.nav ul li,
.icons ul li {
  margin-right: 1rem;
}
.nav ul li:last-child,
.icons ul li:last-child {
  margin-right: 0rem;
}
.nav ul li a {
  color: #000;
  display: inline-block;
  padding-bottom: 0.2rem;
  position: relative;
  font-family: "Roboto Mono", monospace;
}
.nav ul li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--p);
  transform: scaleX(0);
  transition: all 0.2s linear;
}
.nav ul li a:hover,
.nav ul li .active {
  color: var(--p);
}
.nav ul li a:hover::after,
.nav ul li .active::after {
  transform: scaleX(1);
}
.icons ul li a {
  width: 35px;
  height: 35px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
}
.icons ul li a::after {
  content: "";
  background-color: var(--p);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.2s linear;
  border-radius: 50%;
  transform: scale(0);
}
.icons ul li a:hover:after,
.icons ul li .active::after {
  transform: scale(1);
}
.icons ul li a img {
  width: 16px;
  height: 16px;
  z-index: 1;
  transition: all 0.2s linear;
}
.icons ul li a:hover img,
.icons ul li .active img {
  filter: invert();
}
.search_box {
  display: none;
  width: calc((100% - 720px) / 2);
  position: absolute;
  top: 100%;
  right: 0;
  padding: 1rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.4);
  z-index: 999;
}
.search_box .input {
  width: 100%;
  height: 45px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  display: flex;
}
.search_box .input input,
.search_box .input button {
  height: 100%;
  border: none;
  outline: none;
  padding: 0rem 1rem;
}
.search_box .input input {
  width: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.search_box .input button {
  cursor: pointer;
  transition: all 0.2s linear;
}
.search_box .input button:hover {
  background-color: var(--p_l);
}
.navMobile {
  display: none;
}
.icons ul li:last-child a {
  display: none;
}

@media screen and (max-width: 1180px) {
  .search_box {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .search_box {
    width: 100%;
  }
  .nav {
    display: none;
  }
  .logo,
  .icons {
    width: 50%;
  }
  .logo {
    flex-shrink: 1;
  }
  .icons {
    justify-content: flex-end;
  }
  .icons ul li:last-child a {
    display: inline-flex;
  }
  .navMobile {
    display: none;
    width: 100%;
    height: calc(100vh - 37px - 3rem);
    padding: 1.5rem;
    background-color: #fff;
    overflow: hidden;
    overflow-y: auto;
    text-align: center;
  }
  .navMobile ul li a {
    display: inline-block;
    width: 100%;
    padding: 1rem;
    color: #000;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    border-radius: 0.3rem;
  }
  .navMobile ul li:last-child a {
    margin-bottom: 0rem;
  }
}

@media screen and (max-width: 450px) {
  .logo {
    display: flex;
    align-items: center;
  }
  .logo a {
    height: 20px;
  }
  .icons ul li a {
    width: 25px;
    height: 25px;
  }
  .icons ul li a img {
    width: 14px;
    height: 14px;
  }
  .navMobile {
    height: calc(100vh - 26.5px - 3rem);
  }
}

.display {
  display: block;
}
.hide {
  display: none;
}
.overflow_hide {
  overflow: hidden;
}
