.container {
  padding: var(--page_padding);
  padding-top: 0rem;
  display: flex;
  justify-content: space-between;
}
.team {
  width: 23%;
  border-radius: 0.5rem;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 12px 25px 0px;
  overflow: hidden;
}
.image {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.image .quote {
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: var(--page_padding);
  border-bottom: 1px solid var(--p);
  transition: all 0.2s linear;
}
.image:hover .quote {
  top: 0;
}
.image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text {
  padding: 1rem 2rem;
  text-align: center;
}
.text a {
  color: var(--p);
}
.text a:hover {
  text-decoration: underline;
}
.text a h3 {
  font-size: 20px;
}
.text p {
  font-size: 12px;
}

@media screen and (max-width: 950px) {
  .container {
    align-items: center;
    flex-direction: column;
  }
  .team {
    width: 40%;
    margin-bottom: 1.5rem;
  }
  .team:last-child {
    margin-bottom: 0rem;
  }
}
@media screen and (max-width: 550px) {
  .team {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}
