.container {
  padding: var(--page_padding);
  display: flex;
  justify-content: space-between;
  padding-top: 0rem;
}
@media screen and (max-width: 950px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
}
