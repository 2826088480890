.container {
  display: flex;
  padding: 5rem var(--page_padding);
  background-color: var(--d);
  color: #fff;
}
.logo_news {
  width: 100%;
  margin-right: 5rem;
}
.logo_news img {
  margin-bottom: 1rem;
}
.logo_news p {
  font-size: 14px;
  margin-bottom: 1rem;
  color: var(--disable);
}
.logo_news h1 {
  margin-bottom: 0.8rem;
  color: var(--p);
  font-size: 24px;
  font-family: "Roboto Mono", monospace;
}
.logo_news .newsletter {
  display: flex;
}
.logo_news input {
  width: 100%;
  height: 45px;
  margin-bottom: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: transparent;
  padding: 0rem 1rem;
}
.logo_news button {
  padding: 0rem 1rem;
  height: 45px;
  background-color: var(--p);
  color: #fff;
  cursor: pointer;
  transition: all 0.2s linear;
  border: none;
  outline: none;
}
.logo_news button:hover {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-left: none;
  background-color: transparent;
  color: var(--p);
}
.logo_news button:active {
  transform: translateX(-1px);
}
.logo_news .payment img {
  width: 100%;
}
.link_list {
  margin-right: 2rem;
  flex-shrink: 0;
}
.link_list:nth-child(3) {
  margin-right: 5rem;
}
.link_list h2,
.address_payment h2 {
  margin-bottom: 1rem;
  padding-bottom: 0.8rem;
  position: relative;
  font-family: "Roboto Mono", monospace;
  font-size: 20px;
  text-transform: capitalize;
}
.link_list h2::before,
.address_payment h2::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40%;
  height: 3px;
  border-radius: 10px;
  background-color: var(--p);
}
.link_list ul li {
  margin-bottom: 0.5rem;
}
.link_list ul li:last-child {
  margin-bottom: 0rem;
}
.link_list ul li a {
  font-family: "Roboto Mono", monospace;
  transition: all 0.2s linear;
  color: var(--disable);
}
.link_list ul li a:hover {
  color: var(--p);
  padding-left: 0.5rem;
}
.address_payment {
  width: 300px;
  flex-shrink: 0;
}
.address_payment p {
  margin-bottom: 0.8rem;
  color: var(--disable);
}
.address_payment ul {
  display: flex;
}
.address_payment ul li {
  margin-right: 0.7rem;
}
.address_payment ul li:last-child {
  margin-right: 0rem;
}
.address_payment ul li a {
  display: inline-block;
  width: 30px;
  height: 30px;
}
.address_payment ul li a img {
  width: 100%;
  height: 100%;
}
.copyright {
  padding: 1.5rem var(--page_padding);
  background-color: var(--d_l);
}
.copyright p {
  color: var(--disable);
  font-size: 12px;
  font-family: "Roboto Mono", monospace;
}
.copyright p span {
  color: var(--p);
}
.payment_mobile {
  display: none;
}
@media screen and (max-width: 1170px) {
  .container {
    padding: var(--page_padding);
    flex-direction: column;
  }
  .payment {
    display: none;
  }
  .payment_mobile {
    display: block;
  }
  .link_list,
  .address_payment {
    margin-top: 1.5rem;
    width: 100%;
  }
  .payment_mobile img {
    margin-top: var(--page_padding);
    width: 100%;
  }
  .copyright {
    text-align: center;
  }
}
