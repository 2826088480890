.container {
  width: 23%;
}
.image_container {
  margin-bottom: 1rem;
  position: relative;
  overflow: hidden;
}
.buttons {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  z-index: 1;
  transform: translateY(-100%);
  transition: all 0.2s linear;
}
.image_container:hover .buttons {
  transform: translateY(0);
}
.buttons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.9;
  z-index: -1;
}
.buttons button {
  width: 45%;
  height: 40px;
  outline: none;
  border: 1px solid var(--p);
  color: var(--p);
  cursor: pointer;
  background-color: transparent;
  transition: all 0.2s linear;
  font-weight: bold;
}
.buttons button:hover {
  background-color: var(--p);
  color: #fff;
}
.buttons button:active {
  transform: scale(1.05);
}
.image {
  position: relative;
  padding-bottom: 75%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.image img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}
.title h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  margin-bottom: 1rem;
}
.price p {
  font-size: 14px;
}
.price p del {
  margin-right: 0.3rem;
  color: var(--disable);
  text-decoration: line-through;
}
.point {
  margin-top: -2px;
}
.point p {
  font-size: 12px;
}
.gg-trophy {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: 10px;
  height: 12px;
  border: 2px solid var(--p);
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  margin-right: 0.4rem;
  transform: scale(0.8);
}

.gg-trophy::after,
.gg-trophy::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  position: absolute;
}

.gg-trophy::before {
  box-shadow: inset 0 0 0 2px var(--p), 0 2px 0 0 var(--p);
  bottom: -4px;
  width: 6px;
  height: 4px;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  left: 0;
}

.gg-trophy::after {
  width: 16px;
  height: 6px;
  border-left: 2px solid var(--p);
  border-right: 2px solid var(--p);
  left: -5px;
  top: 0;
}
.stars {
  margin-bottom: 0.5rem;
  margin-left: -2px;
  display: flex;
}
.five-pointed-star {
  --star-color: var(--p);
  font-size: 0.5em;
  position: relative;
  display: block;
  width: 0px;
  height: 0px;
  border-right: 1em solid transparent;
  border-bottom: 0.7em solid var(--star-color);
  border-left: 1em solid transparent;
  transform: rotate(35deg);
}
.five-pointed-star:before {
  border-bottom: 0.8em solid var(--star-color);
  border-left: 0.3em solid transparent;
  border-right: 0.3em solid transparent;
  position: absolute;
  height: 0;
  width: 0;
  top: -0.45em;
  left: -0.65em;
  display: block;
  content: "";
  transform: rotate(-35deg);
}
.five-pointed-star:after {
  position: absolute;
  display: block;
  top: 0.03em;
  left: -1.05em;
  width: 0;
  height: 0;
  border-right: 1em solid transparent;
  border-bottom: 0.7em solid var(--star-color);
  border-left: 1em solid transparent;
  transform: rotate(-70deg);
  content: "";
}

@media screen and (max-width: 950px) {
  .container {
    width: 40%;
    margin-bottom: 2rem;
  }
  .container:last-child {
    margin-bottom: 0rem;
  }
}
@media screen and (max-width: 550px) {
  .container {
    width: 100%;
  }
}
