body {
  margin: 0;
  padding: 0;
  background-color: #fafafa;
  font: 11pt 'Tahoma';
  font: 11pt 'Helvetica Neue';
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.page {
  position: relative;
  zoom: 100%;
  width: 21cm;
  min-height: 29.7cm;
  padding: 2cm;
  padding-top: 1.5rem;
  background: white;
}

.admin {
  margin-left: 0.8%;
}

.adminPage {
  zoom: 100%;
  margin-left: 12%;
  width: 21cm;
  min-height: 29.7cm;
  padding: 2cm;
  padding-top: 1.5rem;
  background: white;
}

.subpage {
  padding: 1cm;
  border: 5px red solid;
  height: 256mm;
  outline: 2cm #ffeaea solid;
}

@page {
  size: A4;
  zoom: 80%;
  margin: auto;
}
@media print {
  #adminSideBar {
    display: none;
  }
  .page {
    zoom: 80%;
    margin: auto;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}
