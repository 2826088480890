.container {
  padding-top: 0rem;
  display: flex;
  width: 100%;
  height: 100vh;
}
.address {
  width: 50%;
  height: 100%;
  flex-shrink: 0;
}
.address div:nth-child(2) {
  height: 100% !important;
}
.form {
  width: 50%;
  padding-bottom: var(--page_padding);
}
.inner {
  padding: 0rem var(--page_padding);
}
.inner:first-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.form input {
  width: 100%;
  height: 45px;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0rem 1rem;
  border-radius: 0.5rem;
  font-family: "Roboto Mono", monospace;
}
.button {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 2rem;
}
.button button {
  cursor: pointer;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.8rem 2rem;
  border-radius: 6px;
  background-color: var(--p);
  color: #fff;
  font-family: "Roboto Mono", monospace;
  letter-spacing: 2px;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.2s linear;
}
.button button:hover {
  background-color: transparent;
  color: var(--p);
  box-shadow: rgba(0, 114, 203, 0.16) 0px 1px 2px,
    rgb(0, 114, 203) 0px 0px 0px 1.5px;
}
.button button:active {
  transform: scale(1.05);
}
.form {
  width: 100%;
}
.form h1 {
  margin: var(--page_padding) 0rem 1rem 0rem;
  color: var(--p);
}
.form ul {
  display: flex;
}
.form ul li {
  margin-right: 0.7rem;
}
.form ul li:last-child {
  margin-right: 0rem;
}
.form ul li a {
  display: inline-block;
  width: 30px;
  height: 30px;
}
.form ul li a img {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    height: auto;
  }
  .address,
  .form {
    width: 100%;
  }
  .address div:nth-child(2) {
    height: 400px !important;
  }
}
